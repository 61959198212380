import logo from './logo.png';
import banner from './banner.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo"/>
        <div className="App-content">
          <p>
            Our developers are currently buried in code and caffeine, working tirelessly to bring you something awesome.
            Please check back soon – once we've fixed all the bugs (we promise, only the friendly ones).
          </p>
        </div>
        <div className="App-footer">
          <a
              href="mailto:info@cyberzero.ai"
          >
            Email Us : info@cyberzero.ai
          </a>
        </div>
        <img src={banner} className="App-banner" alt="banner"/>
      </header>
    </div>
  );
}

export default App;
